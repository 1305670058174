import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Http, Response, RequestOptions, Headers } from '@angular/http';

@Injectable()
export class MailingListService {

  constructor(
    private http: Http
  ) { }

  post(url: string, params: Object): Observable<any> {
    return this.http.post(url, params, this.getDefaultRequestOptions())
      .pipe(
        map((response) => {
          return response.json();
        }),
        catchError(() => {
          console.error('Post failed');
          return of('error');
        })
      )
  }

  private getDefaultRequestOptions(formData: boolean = false): RequestOptions {
    let headers: Object = {};
    if (formData) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    } else {
      headers['Content-Type'] = 'application/vnd.api+json';
    }

    return new RequestOptions({ headers: new Headers(headers) });
  }
}
