import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Http, HttpModule, XSRFStrategy } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';

import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';
import { HomeComponent } from './pages/home/home.component';
import { MailingListComponent } from './shared/mailing-list/mailing-list.component';
import { SocialBoxComponent } from './shared/social-box/social-box.component';
import { MailingListService } from './shared/mailing-list/mailing-list.service';
import { QuotesComponent } from './shared/quotes/quotes.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PressComponent } from './pages/press/press.component';
import { SupportComponent } from './pages/support/support.component';
import { StoreComponent } from './shared/store/store.component';
import { ConstructionsComponent } from './pages/constructions/constructions.component';
import { AdsComponent } from './shared/ads/ads.component';


/**
 * Routes
 */
const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch : 'full'
  },
  {
    path: 'home',
    pathMatch : 'full',
    component: HomeComponent,
  },
  {
    path: 'privacy',
    pathMatch : 'full',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'constructions',
    pathMatch : 'full',
    component: ConstructionsComponent,
  },
  {
    path: 'press',
    pathMatch : 'full',
    component: PressComponent,
  },
  {
    path: 'support',
    pathMatch : 'full',
    component: SupportComponent,
  }
  // {
  //   path: 'blog',
  //   pathMatch : 'full',
  //   component: BlogComponent,
  // }
  //{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MailingListComponent,
    SocialBoxComponent,
    QuotesComponent,
    PrivacyPolicyComponent,
    PressComponent,
    SupportComponent,
    StoreComponent,
    ConstructionsComponent,
    AdsComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false }), // , useHash: true <-- debugging purposes only),
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule
  ],
  providers: [
    MailingListService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
