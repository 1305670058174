import { Component, OnInit, AfterViewInit,  ViewChild, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { MailingListService } from './mailing-list.service';

@Component({
  selector: 'app-mailing-list',
  templateUrl: './mailing-list.component.html',
  styleUrls: ['./mailing-list.component.scss']
})
export class MailingListComponent implements OnInit, AfterViewInit {
  @ViewChild('mailChimpForm') mailchimpformView;
  public mailchimpform: any;
  public items: Observable<any[]>;
  public model: any;
  public emailError = false;
  public isThanks = false;
  private collection: AngularFirestoreCollection<any>;

  constructor(
    private db: AngularFirestore,
    private mailingListService: MailingListService
  ) {


    this.collection = this.db.collection<any>('mailing-list');
    this.items = this.db.collection('mailingList').valueChanges();
  }

  ngOnInit() {
    
    this.model = {
      email: '',
      timestamp: ''
    };

    // this.items.subscribe( item => {
    //   console.log('db', item);
    // });


  }

  ngAfterViewInit() {
    //this.mailchimpform = this.mailchimpformView.nativeElement;
    //console.log('mailchimpform', this.mailchimpform);
  }

  submit() {
    if ( this.validateEmail( this.model.email ) ) {
      this.isThanks = true;
      this.collection.add({
        email: this.model.email,
        timestamp: Date.now()
      });

      // let id = '8b15878771';
      // this.mailingListService.post(
      //   'https://usX.api.mailchimp.com/3.0/lists/'+id+'/members/',
      //   {'email_address' : this.model.email}
      // ).subscribe( (res) => {
      //   console.log('mailchimp res', res);
      // });

      //this.mailchimpform.submit();
    } else {
      this.emailError = true;
      setTimeout( () => {
        this.emailError = false;
      }, 1500);
    };
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  }
}
